import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Layout, SearchBox } from '../components/common'
import { MetaData } from '../components/common/meta'
import { frontpageTags, navigation } from '../utils/siteConfig'

const resources = {
  en: (
    <>
      <p>Welcome to our people-powered FAQ! This page contains easily accessible and accurate
      information that addresses Philadelphians' questions about COVID-19's effects on life and
      work in the city. The answers below come from reporting by local newsrooms and
      independent journalists across the city, as well as other verified sources, such as government
      institutions.</p>

      <p>The question list you see is only a small portion of the total that have been asked and
      answered. Please use the search bar function if you do not see your question or topic listed. If
      your question still doesn’t appear, send it to us! Visit our <a href="https://resolvephilly.org/reach-covid-19/">submissions page</a> or text
      COVIDPHL19 to 73224. This is not an urgent help line and we are unable to respond
      individually to every question submitted; If you have questions related to a medical
      emergency, please call 1-800-722-7112.</p>

      <p>Like this FAQ? It can be embedded on ANY website with a single link of code. If you have a
      website and want to embed, or if there is a site you visit frequently that you think should have
      it, please contact julie@resolvephilly.org for more information.</p>

      <p>This is a project of Equally Informed, the latest initiative by Resolve Philly which seeks to
      bridge the information divide for all Philadelphians. To learn more about Equally Informed,
      please visit <a href="https://equallyinformed.com">https://equallyinformed.com</a>.</p>
    </>
  ),
  es: (
    <>
      <p>¡Bienvenido a nuestra página de preguntas frecuentes basadas en personas! Esta página web
      es un esfuerzo para facilitar el acceso a información precisa que responden a las preguntas
      que los residentes de Filadelfia tienen sobre los efectos de la pandemia del coronavirus en la
      vida y el trabajo en la ciudad. Las respuestas a continuación provienen de informes de salas
      de prensa locales y periodistas independientes DE toda la ciudad, así como de otras fuentes
      verificadas, como instituciones gubernamentales.</p>
      <p>Las preguntas que usted ve, son tan solo una pequeña porción de la totalidad que ya han sido
      respondidas. Si usted tiene una pregunta y no la ve, utilice la barra de búsqueda. Si su
      pregunta sigue sin aparecer, envíenosla! Visite nuestra <a href="https://resolvephilly.org/reach-covid-19/">página de envíos</a> o envíe un mensaje
      de texto con la palabra COVIDPHL19 a 73224.Esta no es una línea de ayuda urgente y no
      podemos responder a todas las preguntas presentadas individualmente pero podemos
      procesar su pregunta. Le recordamos que si tiene preguntas relacionadas con una
      emergencia médica, llame al 1-800-722-7112.</p>
      <p>No podemos responder preguntas médicas o aquellas exclusivas a una situación personal. Si
      usted tiene preguntas médicas, llame al 1-800-722-7112.</p>
      <p>¿Le gusta está página? Se puede incluir en CUALQUIER sitio web con un solo enlace de
      código. Si usted tiene un sitio web y desea incluirla o si hay un sitio que visita con frecuencia
      que usted piensa que debe tenerla, por favor póngase en contacto con
      julie@resolvephilly.org para más información.</p>
      <p>Este es un proyecto de Equally Informed Philly (Información Equitativa en Philly), la última
      iniciativa de Resolve Philly que busca disminuir la brecha de información para todos los
      residentes de Filadelfia. Para obtener más información sobre Equally Informed, visite
      <a href="https://equallyinformed.com">https://equallyinformed.com</a>.</p>
    </>
  )
}


/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {
  const posts = data.allGhostPost.edges

  const lang = pageContext.lang || 'en'

  // Build the list of tags for the page
  const topLevelTags = navigation[lang].map((item) => {
    const url = new URL(item.url)
    const id = url.pathname.replace(/\//g, '')
    if (!frontpageTags[lang].includes(id)) return null

    // Get the posts for the tag
    const tagPosts = []
    posts.forEach((post) => {
      if (post.node.tags.find((postTag) => postTag.slug === id)) tagPosts.push(post)
    })

    return { id, slug: url.pathname, posts: tagPosts, ...item }
  }).filter(Boolean)

  const title = lang === 'es' ? 'FAQ sobre la COVID-19 en Filadelfia' : 'Philly COVID-19 FAQ'

  return (
    <>
      <MetaData
        location={location}
        hasAmp={pageContext.hasAmp}
        title={title}
      />
      <Layout isHome={true} isAmp={pageContext.isAmp} lang={lang}>

        {resources[lang]}

        <SearchBox isAmp={pageContext.isAmp} lang={lang} />

        {topLevelTags.map((tag, i) => (
          <div class="related">
            <h2><a href={tag.slug}>{tag.label}</a></h2>

            <ul>
              {tag.posts.map(({ node }) => (
                <li key={node.id}>
                  <a href={`/${node.slug}/`}>{node.title}</a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </Layout>
    </>
  )
}

Index.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!, $tags: [String!]) {
    allGhostPost(
      filter: {
        tags: {
          elemMatch: {
            slug: {
              in: $tags
            }
          }
        }
      },
      sort: { order: DESC, fields: [published_at] },
      limit: $limit,
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
